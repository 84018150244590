import React, { useEffect, useRef } from 'react';
import {
  Icon,
  Headline,
  Label,
  Avatar,
  designSystemToken,
  Button
} from '@lightricks/react-design-system';
import { v4 as uuid } from 'uuid';
import translate from '../../utils/translate';
import styles from './SyncPlatforms.module.scss';
import PlatformList from './PlatformList';
import ProgressBar from './ProgressBar';
import { Platform } from './types/platforms';
import { flowNames } from '../../lib/delta/deltaConstants';
import DeltaHelper from '../../services/delta/DeltaHelper';
import SIGNUP_SCREEN_DISMISSED_SCHEMA from '../../lib/delta/delta-schemas/ppWebCreatorsSignupScreenDismissed';
import Delta from '../../utils/wrappers/Delta';
import SIGNUP_SCREEN_PRESENTED_SCHEMA from '../../lib/delta/delta-schemas/ppWebCreatorsSignupScreenPresented';
import sendSignupButtonPressedEvent from './utils/sendSignupButtonPressedEvent';

type SyncPlatformsContentProps = {
  handleFlowTransition: () => void;
  handleUnfinishedFlow: () => void;
  flowNotCompleted: boolean;
  progressPercentage: number;
  avatarUrl: string | null;
  error: string | null;
  renderErrorBox: () => JSX.Element;
  renderInfoBox: () => JSX.Element;
  PlatformListProps: {
    selectedPlatforms: Platform[];
    connectedPlatforms: Platform[];
    loadingPlatform: string | null;
    onConnectPlatform: (platform: Platform) => void;
  };
  onPlatformSync: boolean;
};

const SCREEN_NAME = 'sync-platforms';

function SyncPlatformsContent({
  handleFlowTransition,
  handleUnfinishedFlow,
  flowNotCompleted,
  progressPercentage,
  avatarUrl,
  error,
  renderErrorBox,
  renderInfoBox,
  PlatformListProps,
  onPlatformSync
}: SyncPlatformsContentProps) {
  const flow = useRef(DeltaHelper.getOrCreateFlow(flowNames.brandsSafetyConnectPlatforms));
  const screenId = useRef(uuid());
  const sendDismissedEventRef = useRef<(flowId: string, screenId: string) => void>();
  useEffect(() => {
    sendDismissedEventRef.current = () => {
      Delta.sendEvent(SIGNUP_SCREEN_DISMISSED_SCHEMA.name, {
        flow_id: flow.current.flow_id,
        screen_presentation_id: screenId.current,
        signup_screen_name: SCREEN_NAME,
        presented_details: JSON.stringify(PlatformListProps)
      });
    };
  }, [PlatformListProps]);

  useEffect(() => {
    Delta.sendEvent(SIGNUP_SCREEN_PRESENTED_SCHEMA.name, {
      flow_id: flow.current.flow_id,
      screen_presentation_id: screenId.current,
      signup_screen_name: SCREEN_NAME,
      presented_details: JSON.stringify(PlatformListProps)
    });
    return () => {
      if (sendDismissedEventRef.current) {
        sendDismissedEventRef.current(flow.current.flow_id, screenId.current);
      }
    };
  }, []);

  return (
    <div className={styles.container} data-testid="sync-platforms">
      <div className={styles.content}>
        <div className={styles.headerRow}>
          <Button
            onClick={() => {
              sendSignupButtonPressedEvent(screenId.current, SCREEN_NAME, `${SCREEN_NAME}-back`);
              handleFlowTransition();
            }}
            data-testid="back-button"
            appearance="neutral"
            mode="plain"
            size="large"
            className={styles.backButton}>
            <Icon
              name="Actions-Arrow-Back-Small"
              size="large"
              appearance="primary"
              style={{ visibility: onPlatformSync ? 'hidden' : 'visible' }}
            />
          </Button>
          {flowNotCompleted && (
            <span
              onClick={() => {
                sendSignupButtonPressedEvent(screenId.current, SCREEN_NAME, `${SCREEN_NAME}-skip`);
                handleUnfinishedFlow();
              }}
              role="button"
              tabIndex={0}
              className={styles.skipButton}>
              <Label size="lg">Skip</Label>
            </span>
          )}
        </div>
        <div className={styles.mainContent}>
          <div className={styles.progressBarWrapper}>
            <ProgressBar value={progressPercentage} />
            {translate('components.brand-safety.sign-up.sync-platforms.progress', {
              progress: progressPercentage.toFixed(0)
            })}
          </div>
          <div className={styles.titleSection}>
            {avatarUrl ? (
              <Avatar
                size="3xl"
                src={avatarUrl}
                type="picture"
                variant="circular"
                background={designSystemToken('semantic.bg.tertiary')}
              />
            ) : (
              <Headline size="xl" className={styles.avatarPlaceholder}>
                {translate('components.brand-safety.sign-up.sync-platforms.heading')}
              </Headline>
            )}
            {error ? renderErrorBox() : renderInfoBox()}
          </div>
          <PlatformList
            {...PlatformListProps}
            screenId={screenId.current}
            screenName={SCREEN_NAME}
          />
        </div>
      </div>
    </div>
  );
}

export default SyncPlatformsContent;

import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Button, Headline, Body, Label } from '@lightricks/react-design-system';
import signupAnimation from '../../../../assets/lotties/brand-safety/signup_screen_animation.json';
import translate from '../../../../utils/translate';
import styles from '../../brand-safety/styles/BrandIntro.module.scss';

export type BrandIntroProps = {
  brandName: string | undefined;
  mainButtonClick: () => void;
  mainButtonText: string;
  secondaryElement?: React.ReactNode;
};

function BrandIntro(props: BrandIntroProps) {
  const { brandName, mainButtonClick, mainButtonText, secondaryElement } = props;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.animationContainer}>
          <Player autoplay loop src={signupAnimation} style={{ width: '100%', height: '100%' }} />
        </div>
        <Body size="lg" className={styles.body}>
          {translate('components.register-form.brand-safety.headline')}
        </Body>
        <Headline size="lg" className={styles.headline}>
          {translate('components.register-form.brand-safety.sub-headline')}
        </Headline>
        <Headline size="lg" className={styles.brandName}>
          {brandName}
        </Headline>
        <div className={styles.subContent}>
          <Button
            appearance="neutral"
            mode="filled"
            size="large"
            onClick={mainButtonClick}
            className={styles.joinWithEmailButton}
            data-testid="join-now-button">
            {mainButtonText}
          </Button>
          {secondaryElement}
        </div>
      </div>
    </div>
  );
}

export default BrandIntro;

import React, { useEffect, useRef } from 'react';
import { Button, Icon, Headline } from '@lightricks/react-design-system';
import { v4 as uuid } from 'uuid';
import { Platform } from './types/platforms';
import styles from './SelectPlatformsContent.module.scss';
import translate from '../../utils/translate';
import SIGNUP_SCREEN_PRESENTED_SCHEMA from '../../lib/delta/delta-schemas/ppWebCreatorsSignupScreenPresented';
import { flowNames } from '../../lib/delta/deltaConstants';
import DeltaHelper from '../../services/delta/DeltaHelper';
import Delta from '../../utils/wrappers/Delta';
import SIGNUP_SCREEN_DISMISSED_SCHEMA from '../../lib/delta/delta-schemas/ppWebCreatorsSignupScreenDismissed';
import sendSignupButtonPressedEvent from './utils/sendSignupButtonPressedEvent';

interface SelectPlatformsContentProps {
  handleFlowTransition: () => void;
  handleNextClick: () => void;
  selectedPlatforms: Platform[];
  connectedPlatforms: Platform[];
  togglePlatformSelection: (platform: Platform) => void;
  SOCIAL_PLATFORMS: { name: Platform; icon: string }[];
}

const SCREEN_NAME = 'select-platforms';

function SelectPlatformsContent({
  handleFlowTransition,
  handleNextClick,
  selectedPlatforms,
  connectedPlatforms,
  togglePlatformSelection,
  SOCIAL_PLATFORMS
}: SelectPlatformsContentProps) {
  const screenId = useRef(uuid());
  const sendDismissedEventRef = useRef<(flowId: string, screenId: string) => void>();
  useEffect(() => {
    const flow = DeltaHelper.getOrCreateFlow(flowNames.brandsSafetyConnectPlatforms);
    Delta.sendEvent(SIGNUP_SCREEN_PRESENTED_SCHEMA.name, {
      flow_id: flow.flow_id,
      screen_presentation_id: screenId.current,
      signup_screen_name: SCREEN_NAME,
      presented_details: JSON.stringify({ selectedPlatforms, connectedPlatforms, SOCIAL_PLATFORMS })
    });
    return () => {
      if (sendDismissedEventRef.current) {
        sendDismissedEventRef.current(flow.flow_id, screenId.current);
      }
    };
  }, []);

  useEffect(() => {
    sendDismissedEventRef.current = (flowId: string, inputScreenId: string) => {
      Delta.sendEvent(SIGNUP_SCREEN_DISMISSED_SCHEMA.name, {
        flow_id: flowId,
        screen_presentation_id: inputScreenId,
        signup_screen_name: SCREEN_NAME,
        presented_details: JSON.stringify({
          selectedPlatforms,
          connectedPlatforms,
          SOCIAL_PLATFORMS
        })
      });
    };
  }, [selectedPlatforms, connectedPlatforms]);

  const renderPlatformButton = ({ name, icon }: { name: Platform; icon: string }) => {
    const isSelected = selectedPlatforms.includes(name);
    const isConnected = connectedPlatforms.includes(name);

    return (
      <div
        key={name}
        className={`${styles.platform} ${isSelected ? styles.selectedPlatform : ''} ${
          isConnected ? styles.connectedPlatform : ''
        }`}
        onClick={() => {
          if (!isConnected) {
            sendSignupButtonPressedEvent(screenId.current, SCREEN_NAME, `${SCREEN_NAME}-${name}`);
            togglePlatformSelection(name);
          }
        }}
        role="button"
        tabIndex={0}>
        <label htmlFor={name} className={styles.label}>
          <Icon
            name={`Social-${icon}` as any}
            size="large"
            appearance={isSelected ? 'white' : 'primary'}
          />
          <p>{name}</p>
        </label>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.headerRow}>
          <span
            className={styles.backButton}
            onClick={() => {
              sendSignupButtonPressedEvent(screenId.current, SCREEN_NAME, `${SCREEN_NAME}-back`);
              handleFlowTransition();
            }}
            data-testid="close-button"
            role="button"
            tabIndex={0}>
            <Icon name="Actions-Close-Normal" size="medium" appearance="primary" />
          </span>
        </div>
        <div className={styles.mainContent}>
          <div className={styles.headlineRow}>
            <Headline size="md" className={styles.headline}>
              {translate('components.brand-safety.sign-up.select-platforms.headline')}
            </Headline>
          </div>
          <div className={styles.platforms}>{SOCIAL_PLATFORMS.map(renderPlatformButton)}</div>
          <Button
            onClick={() => {
              sendSignupButtonPressedEvent(screenId.current, SCREEN_NAME, `${SCREEN_NAME}-next`);
              handleNextClick();
            }}
            disabled={selectedPlatforms.length === 0}
            appearance="neutral"
            mode="filled"
            size="large"
            className={styles.nextButton}>
            {translate('components.brand-safety.sign-up.select-platforms.next')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SelectPlatformsContent;

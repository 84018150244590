import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import useUserQuery from '../../hooks/queries/use-user-query';
import SyncPlatforms from './SyncPlatforms';
import UnfinishedFlow from './UnfinishedFlow';
import { Platform } from './types/platforms';
import { providerToPlatformMap } from './utils/platformMappings';
import { flowNames } from '../../lib/delta/deltaConstants';
import DeltaHelper, { Flow } from '../../services/delta/DeltaHelper';
import Delta from '../../utils/wrappers/Delta';
import SelectPlatformsContent from './SelectPlatformsContent';
import SIGNUP_FLOW_STARTED_SCHEMA from '../../lib/delta/delta-schemas/ppWebCreatorsSignupFlowStarted';

const SOCIAL_PLATFORMS: { name: Platform; icon: string }[] = [
  { name: 'Instagram', icon: 'Instagram' },
  { name: 'TikTok', icon: 'TikTok' },
  { name: 'YouTube', icon: 'YouTube' },
  { name: 'Facebook', icon: 'Facebook' }
];

type Page = 'select' | 'sync' | 'unfinished';

function SelectPlatforms() {
  const { user: userData } = useUserQuery({});
  const [selectedPlatforms, setSelectedPlatforms] = useState<Platform[]>([]);
  const [connectedPlatforms, setConnectedPlatforms] = useState<Platform[]>([]);
  const [currentView, setCurrentView] = useState<Page>('select');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const flow = DeltaHelper.getOrCreateFlow(flowNames.brandsSafetyConnectPlatforms);
    const scInviteCode = searchParams.get('sc_invite_code') ?? 'in-platform';
    Delta.sendEvent(SIGNUP_FLOW_STARTED_SCHEMA.name, {
      flow_id: flow.flow_id,
      flow_name: flow.flow_name,
      source: `inviteCode: ${scInviteCode}`
    });
  }, []);

  useEffect(() => {
    if (userData?.identities) {
      const platforms = userData.identities.map((identity: { provider: string | number }) => {
        const provider = identity.provider as keyof typeof providerToPlatformMap;
        return providerToPlatformMap[provider];
      });
      setConnectedPlatforms(platforms);
      setSelectedPlatforms((prev) => [...new Set([...prev, ...platforms])]);
    }
  }, [userData]);

  useEffect(() => {
    const platformsFromQuery =
      searchParams
        .get('selectedPlatforms')
        ?.split(',')
        .filter((platform): platform is Platform =>
          SOCIAL_PLATFORMS.some(({ name }) => name === platform)
        ) || [];
    setSelectedPlatforms((prev) => [...new Set([...prev, ...platformsFromQuery])]);
    setCurrentView(platformsFromQuery.length > 0 ? 'sync' : 'select');
  }, []);

  const togglePlatformSelection = useCallback(
    (platform: Platform) => {
      if (connectedPlatforms.includes(platform)) {
        return;
      }

      setSelectedPlatforms((prev) =>
        prev.includes(platform) ? prev.filter((p) => p !== platform) : [...prev, platform]
      );
    },
    [connectedPlatforms]
  );

  const updateSearchParams = useCallback(
    (newParams: Record<string, string>) => {
      const params = new URLSearchParams(searchParams);
      Object.entries(newParams).forEach(([key, value]) => params.set(key, value));
      setSearchParams(params);
    },
    [searchParams, setSearchParams]
  );

  const handleNextClick = useCallback(() => {
    if (selectedPlatforms.length === 0) return;
    if (selectedPlatforms.every((platform) => connectedPlatforms.includes(platform))) {
      setCurrentView('sync');
      return;
    }
    updateSearchParams({ selectedPlatforms: selectedPlatforms.join(',') });
    setCurrentView('sync');
  }, [selectedPlatforms, updateSearchParams]);

  const handleFlowTransition = useCallback(() => {
    setCurrentView((prev) => (prev === 'sync' ? 'select' : 'unfinished'));
  }, []);

  const handleFinishConnecting = useCallback(() => {
    setCurrentView('select');
  }, [updateSearchParams]);

  if (currentView === 'unfinished') {
    return (
      <UnfinishedFlow
        handleFinishConnecting={handleFinishConnecting}
        missingPlatform={undefined}
        handleBackToSelect={() => setCurrentView('select')}
      />
    );
  }

  if (currentView === 'sync') {
    return (
      <SyncPlatforms
        selectedPlatforms={selectedPlatforms}
        handleFlowTransition={handleFlowTransition}
      />
    );
  }

  return (
    <SelectPlatformsContent
      handleFlowTransition={handleFlowTransition}
      handleNextClick={handleNextClick}
      selectedPlatforms={selectedPlatforms}
      connectedPlatforms={connectedPlatforms}
      togglePlatformSelection={togglePlatformSelection}
      SOCIAL_PLATFORMS={SOCIAL_PLATFORMS}
    />
  );
}

export default SelectPlatforms;

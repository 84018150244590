import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Headline, Label, Icon } from '@lightricks/react-design-system';
import { Player } from '@lottiefiles/react-lottie-player';
import { v4 as uuid } from 'uuid';
import useUserQuery from '../../hooks/queries/use-user-query';
import userNotifications from '../../api/updaters/userNotifications';
import unfinishedFlowAnimation from '../../assets/lotties/brand-safety/unfinished_flow_animation.json';
import sandClockPendingAnimation from '../../assets/lotties/brand-safety/sand_clock_pending_animation.json';
import translate from '../../utils/translate';
import styles from './UnfinishedFlow.module.scss';
import { flowNames } from '../../lib/delta/deltaConstants';
import DeltaHelper from '../../services/delta/DeltaHelper';
import SIGNUP_SCREEN_PRESENTED_SCHEMA from '../../lib/delta/delta-schemas/ppWebCreatorsSignupScreenPresented';
import Delta from '../../utils/wrappers/Delta';
import SIGNUP_SCREEN_DISMISSED_SCHEMA from '../../lib/delta/delta-schemas/ppWebCreatorsSignupScreenDismissed';
import sendSignupButtonPressedEvent from './utils/sendSignupButtonPressedEvent';

export type UnfinishedFlowProps = {
  handleFinishConnecting: () => void;
  missingPlatform?: string;
  helpLink?: string;
  handleBackToSelect: () => void;
};

const SCREEN_NAME = 'unfinished-flow';

const FINISH_SYNC_EMAIL_SENT_KEY = 'finish-sync-email-sent';

const defaultHelpLink =
  'https://popularpays.zendesk.com/hc/en-us/sections/360012325391-Account-Set-Up';

const stateMap = (
  brandName: string,
  missingPlatform: string | undefined,
  handleFinishConnecting: () => void,
  handleBackToSelect: () => void
) => {
  const emailSent = localStorage.getItem(FINISH_SYNC_EMAIL_SENT_KEY);
  return {
    isMaybeLater: {
      headline: translate('components.brand-safety.sign-up.unfinished-flow.almost-there'),
      description: emailSent
        ? translate('components.brand-safety.sign-up.unfinished-flow.returning-description')
        : translate('components.brand-safety.sign-up.unfinished-flow.first-time-description'),
      buttonText: translate(
        'components.brand-safety.sign-up.unfinished-flow.discover-more-campaigns'
      ),
      animation: sandClockPendingAnimation,
      onButtonClick: handleBackToSelect,
      showCampaignsLink: true
    },
    missingPlatform: {
      headline: translate('components.brand-safety.sign-up.unfinished-flow.dont-miss-out', {
        brandName
      }),
      description: translate('components.brand-safety.sign-up.unfinished-flow.connect-socials'),
      buttonText: translate('components.brand-safety.sign-up.unfinished-flow.connect-platform', {
        platform: missingPlatform
      }),
      animation: unfinishedFlowAnimation,
      onButtonClick: handleFinishConnecting,
      showCampaignsLink: false
    },
    default: {
      headline: translate('components.brand-safety.sign-up.unfinished-flow.dont-miss-out', {
        brandName
      }),
      description: translate('components.brand-safety.sign-up.unfinished-flow.connect-socials'),
      buttonText: translate('components.brand-safety.sign-up.unfinished-flow.finish-connecting'),
      animation: unfinishedFlowAnimation,
      onButtonClick: handleFinishConnecting,
      showCampaignsLink: false
    }
  };
};

function UnfinishedFlow(props: UnfinishedFlowProps) {
  const {
    handleFinishConnecting,
    missingPlatform,
    helpLink = defaultHelpLink,
    handleBackToSelect
  } = props;
  const [searchParams] = useSearchParams();
  const brandName = searchParams.get('brand') || '';
  const [isMaybeLater, setIsMaybeLater] = useState(false);
  const { user: userData } = useUserQuery({});
  const userId = userData?.id;
  const screenId = useRef(uuid());
  const flow = useRef(DeltaHelper.getOrCreateFlow(flowNames.brandsSafetyConnectPlatforms));

  useEffect(() => {
    Delta.sendEvent(SIGNUP_SCREEN_PRESENTED_SCHEMA.name, {
      flow_id: flow.current.flow_id,
      screen_presentation_id: screenId.current,
      signup_screen_name: SCREEN_NAME,
      presented_details: JSON.stringify({ missingPlatform })
    });
    return () => {
      Delta.sendEvent(SIGNUP_SCREEN_DISMISSED_SCHEMA.name, {
        flow_id: flow.current.flow_id,
        screen_presentation_id: screenId.current,
        signup_screen_name: SCREEN_NAME,
        presented_details: JSON.stringify({ missingPlatform })
      });
    };
  }, []);

  const handleMaybeLater = () => setIsMaybeLater(true);

  useEffect(() => {
    const sendEmail = async () => {
      const emailSent = localStorage.getItem(FINISH_SYNC_EMAIL_SENT_KEY);
      if (!userId || !brandName || !isMaybeLater || emailSent) return;

      try {
        await userNotifications.sendUnfinishedFlowEmail(userId, brandName);
        localStorage.setItem(FINISH_SYNC_EMAIL_SENT_KEY, 'true');
      } catch (error) {
        console.error('Failed to send finished flow email:', error);
      }
    };

    sendEmail();
  }, [userId, brandName, isMaybeLater]);

  const getCurrentState = () => {
    if (isMaybeLater) {
      return stateMap(brandName, missingPlatform, handleFinishConnecting, handleBackToSelect)
        .isMaybeLater;
    }
    if (missingPlatform) {
      return stateMap(brandName, missingPlatform, handleFinishConnecting, handleBackToSelect)
        .missingPlatform;
    }
    return stateMap(brandName, missingPlatform, handleFinishConnecting, handleBackToSelect).default;
  };

  const currentState = getCurrentState();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Player autoplay loop src={currentState.animation} className={styles.animation} />
        <div className={styles.text}>
          <Headline size="lg" className={styles.headline}>
            {currentState.headline}
          </Headline>
          <Label size="lg">{currentState.description}</Label>
        </div>

        <div className={styles.buttons}>
          {isMaybeLater ? (
            <span
              onClick={() => {
                sendSignupButtonPressedEvent(screenId.current, SCREEN_NAME, `${SCREEN_NAME}-back`);
                handleBackToSelect();
              }}
              role="button"
              tabIndex={0}
              className={styles.finishFlowButton}>
              <Label size="lg">
                {translate('components.brand-safety.sign-up.unfinished-flow.take-me-back')}
              </Label>
            </span>
          ) : (
            <>
              <Button
                onClick={() => {
                  sendSignupButtonPressedEvent(
                    screenId.current,
                    SCREEN_NAME,
                    `${SCREEN_NAME}-${currentState.buttonText}`
                  );
                  currentState.onButtonClick();
                }}
                appearance="neutral"
                size="large"
                mode="filled">
                {missingPlatform && (
                  <Icon
                    name={`Social-${missingPlatform}` as any}
                    size="medium"
                    appearance="white"
                  />
                )}
                {currentState.buttonText}
              </Button>
              <div>
                <span
                  onClick={() => {
                    sendSignupButtonPressedEvent(
                      screenId.current,
                      SCREEN_NAME,
                      `${SCREEN_NAME}-maybe-later`
                    );
                    handleMaybeLater();
                  }}
                  role="button"
                  tabIndex={0}>
                  <Label size="lg" className={styles.maybeLater}>
                    {translate('components.brand-safety.sign-up.unfinished-flow.maybe-later')}
                  </Label>
                </span>
                <Label size="lg" className={styles.helpText}>
                  <a
                    href={helpLink}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      sendSignupButtonPressedEvent(
                        screenId.current,
                        SCREEN_NAME,
                        `${SCREEN_NAME}-help`
                      );
                    }}>
                    {translate('components.brand-safety.sign-up.unfinished-flow.need-help')}
                  </a>
                  {translate('components.brand-safety.sign-up.unfinished-flow.guidance')}
                </Label>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default UnfinishedFlow;

import React, { useRef } from 'react';
import {
  Button,
  Checkbox,
  Input,
  Headline,
  Icon,
  Label,
  Body
} from '@lightricks/react-design-system';
import { v4 as uuid } from 'uuid';
import AppFooter from '../../../../components/app-footer/AppFooter';
import translate from '../../../../utils/translate';
import styles from '../../brand-safety/styles/EmailConfirmation.module.scss';
import sendSignupButtonPressedEvent from '../../../../components/brand-safety/utils/sendSignupButtonPressedEvent';

export interface EmailConfirmationProps {
  form: {
    email: string;
    isTermsChecked: boolean;
  };
  updateForm: (field: string, value: string | boolean) => void;
  validationErrors: {
    email: string;
    isTermsChecked: string;
  };
  isSubmitting: boolean;
  handleJoinNow: () => void;
  handleBack: () => void;
  setEmailValidation: (isValid: boolean) => void;
  emailValidators: Array<{
    validator: (email: string) => boolean;
    errorMessage: string;
  }>;
  emailErrorMessage: string;
  setEmailValue: (value: string) => void;
  hideLoginLink?: boolean;
  hideTermsCheckbox?: boolean;
}

const SCREEN_NAME = 'email-confirmation';

function EmailConfirmation(props: EmailConfirmationProps) {
  const {
    form,
    updateForm,
    validationErrors,
    isSubmitting,
    handleJoinNow,
    handleBack,
    setEmailValidation,
    emailValidators,
    emailErrorMessage,
    setEmailValue,
    hideLoginLink,
    hideTermsCheckbox
  } = props;
  const isDisabled =
    !form.email || !form.isTermsChecked || !!validationErrors.email || isSubmitting;

  const screenId = useRef(uuid());

  const sendButtonPressedEvent = (buttonName: string) => {
    sendSignupButtonPressedEvent(screenId.current, SCREEN_NAME, buttonName);
  };

  const renderTermsAndPrivacy = () => (
    <Body size="md" className={styles.termsLabel}>
      {translate('components.register-form.brand-safety.terms-label')}
    </Body>
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <span
          onClick={handleBack}
          role="button"
          className={styles.closeButton}
          tabIndex={0}
          data-testid="close-button">
          <Icon name="Actions-Close-Normal" size="large" appearance="primary" />
        </span>
        <div className={styles.mainContent}>
          <Headline size="lg" className={styles.headline}>
            {translate('components.register-form.brand-safety.confirm-email')}
          </Headline>

          <Input
            validatorOptions={{
              type: 'email',
              onValidation: setEmailValidation,
              validators: emailValidators,
              overrideError: emailErrorMessage,
              onChangeText: setEmailValue
            }}
            placeholder={translate('components.register-form.email-placeholder')}
            value={form.email}
            className={styles.input}
            testID="signup--email-input"
          />

          {!hideTermsCheckbox && (
            <Checkbox
              label={renderTermsAndPrivacy()}
              checked={form.isTermsChecked}
              onChange={() => {
                sendButtonPressedEvent('terms-checkbox');
                updateForm('isTermsChecked', !form.isTermsChecked);
              }}
              error={validationErrors.isTermsChecked}
            />
          )}

          <Button
            onClick={() => {
              handleJoinNow();
              sendButtonPressedEvent('Join');
            }}
            isLoading={isSubmitting}
            disabled={isDisabled}
            appearance="neutral"
            size="large"
            mode="filled"
            className={styles.joinButton}>
            {translate('components.register-form.brand-safety.join')}
          </Button>

          <footer className={styles.footer}>
            {!hideLoginLink && (
              <Label
                size="lg"
                className={styles.loginLabel}
                onClick={() => {
                  sendButtonPressedEvent('login');
                }}>
                {translate('components.register-form.brand-safety.already-have-account')} &nbsp;
                <a href="/auth/login" className={styles.loginLink}>
                  {translate('components.register-form.brand-safety.login')}
                </a>
              </Label>
            )}
            <AppFooter />
          </footer>
        </div>
      </div>
    </div>
  );
}

export default EmailConfirmation;

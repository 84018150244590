import React from 'react';
import { Body, designSystemToken } from '@lightricks/react-design-system';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import styles from './DateInputWithPicker.module.scss';

interface DateInputWithPickerProps {
  date?: Date;
  errorMessage?: string;
  onChange: (date: Date | null) => void;
  label: string;
}

function DateInputWithPicker({ date, errorMessage, onChange, label }: DateInputWithPickerProps) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={label}
            value={date ? dayjs(date) : null}
            onChange={(newValue) => {
              onChange(newValue ? newValue.toDate() : null);
            }}
            maxDate={dayjs()}
            className={styles.datePicker}
            disableHighlightToday
            sx={{
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
                borderBottom: '1px solid !important',
                borderRadius: '0 !important'
              },
              '.MuiInputLabel-root, .MuiInputLabel-root.Mui-focused, .MuiOutlinedInput-input .MuiOutlinedInput-base':
                {
                  fontFamily: 'Brown LL',
                  color: designSystemToken('semantic.fg.secondary')
                }
            }}
            slotProps={{
              day: {
                sx: {
                  '&.Mui-selected': {
                    backgroundColor: `${designSystemToken('semantic.bg.brand')} !important`,
                    color: `${designSystemToken('semantic.fg.white')} !important`
                  },
                  '&.MuiButtonBase-root:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor: `${designSystemToken('semantic.bg.brand')} !important`
                  },
                  '&.MuiPickersCalendarHeader-label': {
                    fontFamily: 'Brown LL'
                  },
                  '&.MuiPickersDay-root:focus': {
                    backgroundColor: 'transparent'
                  }
                }
              }
            }}
          />
        </LocalizationProvider>
        {errorMessage && (
          <Body
            size="sm"
            className={styles.otpError}
            color={designSystemToken('semantic.fg.danger')}>
            {errorMessage}
          </Body>
        )}
      </div>
    </div>
  );
}

export default DateInputWithPicker;

import React, { useState, useEffect, useRef } from 'react';
import { Button, Headline, Body, Icon } from '@lightricks/react-design-system';
import { v4 as uuid } from 'uuid';
import { useMutation } from '@tanstack/react-query';
import userUpdaters from '../../api/updaters/user';
import useUserQuery from '../../hooks/queries/use-user-query';
import SelectPlatforms from './SelectPlatforms';
import UnfinishedFlow from './UnfinishedFlow';
import translate from '../../utils/translate';
import styles from './BirthdayInput.module.scss';
import DateInputWithPicker from '../DateInputWithPicker/DateInputWithPicker';
import SIGNUP_SCREEN_DISMISSED_SCHEMA from '../../lib/delta/delta-schemas/ppWebCreatorsSignupScreenDismissed';
import SIGNUP_SCREEN_PRESENTED_SCHEMA from '../../lib/delta/delta-schemas/ppWebCreatorsSignupScreenPresented';
import Delta from '../../utils/wrappers/Delta';
import { flowNames } from '../../lib/delta/deltaConstants';
import DeltaHelper from '../../services/delta/DeltaHelper';
import { queryClient } from '../../utils/reactQueryClient';

const SCREEN_NAME = 'birthday-input';

function BirthdayInput() {
  const [birthdate, setBirthdate] = useState<Date | undefined>(undefined);
  const [error, setError] = useState('');
  const [showUnfinishedFlow, setShowUnfinishedFlow] = useState(false);
  const { user: userData } = useUserQuery({});
  const screenId = useRef(uuid());
  const flow = useRef(DeltaHelper.getOrCreateFlow(flowNames.brandsSafetyConnectPlatforms));
  const updateBirthdayMutation = useMutation({
    mutationFn: (date: Date) => userUpdaters.updateBirthdate(date.toISOString()),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
    },
    onError: (err) => {
      setError('Failed to update birthday. Please try again.');
      console.error('Failed to update birthdate:', err);
    }
  });

  useEffect(() => {
    Delta.sendEvent(SIGNUP_SCREEN_PRESENTED_SCHEMA.name, {
      flow_id: flow.current.flow_id,
      screen_presentation_id: screenId.current,
      signup_screen_name: SCREEN_NAME,
      presented_details: JSON.stringify(birthdate)
    });
    return () => {
      Delta.sendEvent(SIGNUP_SCREEN_DISMISSED_SCHEMA.name, {
        flow_id: flow.current.flow_id,
        screen_presentation_id: screenId.current,
        signup_screen_name: SCREEN_NAME,
        presented_details: JSON.stringify(birthdate)
      });
    };
  }, []);

  const validateDate = (date: Date) => {
    const today = new Date();
    const minDate = new Date();
    minDate.setFullYear(today.getFullYear() - 100);
    const maxDate = new Date();
    maxDate.setFullYear(today.getFullYear() - 13);

    if (date > maxDate) {
      setError(translate('components.brand-safety.sign-up.birthday-input.age-error'));
      return false;
    }
    if (date < minDate) {
      setError(translate('components.brand-safety.sign-up.birthday-input.valid-date-error'));
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!birthdate) {
      setError(translate('components.brand-safety.sign-up.birthday-input.date-error'));
      return;
    }

    if (validateDate(birthdate)) {
      setError('');
      updateBirthdayMutation.mutate(birthdate);
    }
  };

  const handleBack = () => {
    setShowUnfinishedFlow(true);
  };

  if (showUnfinishedFlow) {
    return (
      <UnfinishedFlow
        handleFinishConnecting={() => setShowUnfinishedFlow(false)}
        handleBackToSelect={() => setShowUnfinishedFlow(false)}
      />
    );
  }

  if (userData?.birthDate) {
    return <SelectPlatforms />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.headerRow}>
          <span
            onClick={handleBack}
            role="button"
            className={styles.closeButton}
            tabIndex={0}
            data-testid="close-button">
            <Icon name="Actions-Close-Normal" size="medium" appearance="primary" />
          </span>
        </div>
        <div className={styles.mainContent}>
          <Headline size="lg" className={styles.headline}>
            {translate('components.brand-safety.sign-up.birthday-input.headline')}
          </Headline>

          <Body size="md" className={styles.body}>
            {translate('components.brand-safety.sign-up.birthday-input.description')}
          </Body>

          <div className={styles.inputContainer}>
            <DateInputWithPicker
              label={translate('components.brand-safety.sign-up.birthday-input.date-label')}
              date={birthdate}
              errorMessage={error}
              onChange={(date) => setBirthdate(date ?? undefined)}
            />
          </div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={handleSubmit}
              disabled={!birthdate || updateBirthdayMutation.isLoading}
              appearance="neutral"
              size="large"
              mode="filled"
              className={styles.nextButton}>
              {translate('components.brand-safety.sign-up.birthday-input.next')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BirthdayInput;

import api from '../index';

const userUpdaters = {
  addCampaignCreatorMatch: async (campaignId: string) => {
    return api.post(
      `/user/campaign_creator_matches`,
      {},
      {
        params: { campaign_invitation: campaignId }
      }
    );
  },

  acceptTerms: async (currentTosVersion: string) => {
    return api.put(`/user/`, { acceptedTosVersion: currentTosVersion });
  },

  updateBirthdate: async (birthDate: string) => {
    return api.put(`/user/`, { birthDate });
  }
};

export default userUpdaters;

import React, { useEffect, useRef } from 'react';
import { Headline, Body, Icon, Label } from '@lightricks/react-design-system';
import { useSearchParams } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import { v4 as uuid } from 'uuid';
import useUserQuery from '../../hooks/queries/use-user-query';
import userNotifications from '../../api/updaters/userNotifications';
import sucessAnimation from '../../assets/lotties/brand-safety/success_animation.json';
import translate from '../../utils/translate';
import styles from './FinishedFlow.module.scss';
import SIGNUP_SCREEN_PRESENTED_SCHEMA from '../../lib/delta/delta-schemas/ppWebCreatorsSignupScreenPresented';
import { flowNames } from '../../lib/delta/deltaConstants';
import DeltaHelper from '../../services/delta/DeltaHelper';
import Delta from '../../utils/wrappers/Delta';
import SIGNUP_SCREEN_DISMISSED_SCHEMA from '../../lib/delta/delta-schemas/ppWebCreatorsSignupScreenDismissed';
import sendSignupButtonPressedEvent from './utils/sendSignupButtonPressedEvent';

const SCREEN_NAME = 'finished-flow';

function FinishedFlow() {
  const [searchParams] = useSearchParams();
  const brandName = searchParams.get('brand') || '';
  const redirectToHome = async () => {
    await Delta.flushEvents();
    window.location.href = '/';
  };
  const { user: userData } = useUserQuery({});
  const userId = userData?.id;
  const screenId = useRef(uuid());

  useEffect(() => {
    const flow = DeltaHelper.getOrCreateFlow(flowNames.brandsSafetyConnectPlatforms);
    Delta.sendEvent(SIGNUP_SCREEN_PRESENTED_SCHEMA.name, {
      flow_id: flow.flow_id,
      screen_presentation_id: screenId,
      signup_screen_name: SCREEN_NAME
    });
    return () => {
      Delta.sendEvent(SIGNUP_SCREEN_DISMISSED_SCHEMA.name, {
        flow_id: flow.flow_id,
        screen_presentation_id: screenId,
        signup_screen_name: SCREEN_NAME
      });
    };
  }, []);

  useEffect(() => {
    const sendEmail = async () => {
      if (!userId || !brandName) return;

      try {
        await userNotifications.sendFinishedFlowEmail(userId, brandName);
      } catch (error) {
        console.error('Failed to send finished flow email:', error);
      }
    };

    sendEmail();
  }, [userId, brandName]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Player
          autoplay
          loop={false}
          keepLastFrame
          src={sucessAnimation}
          className={styles.animation}
        />
        <div className={styles.text}>
          <Headline size="lg">
            {translate('components.brand-safety.sign-up.finished-flow.all-set')}
          </Headline>
          <div className={styles.list}>
            <Icon name="Actions-Accept" size="large" appearance="primary" />
            <Body size="lg">
              {translate('components.brand-safety.sign-up.finished-flow.in-the-running', {
                brandName
              })}
            </Body>
          </div>
          <div className={styles.list}>
            <Icon name="Actions-Accept" size="large" appearance="primary" />
            <Body size="lg">
              {translate('components.brand-safety.sign-up.finished-flow.account-live')}
            </Body>
          </div>
          <div className={styles.list}>
            <Icon name="Actions-Accept" size="large" appearance="primary" />
            <Body size="lg">
              {translate('components.brand-safety.sign-up.finished-flow.check-email')}
            </Body>
          </div>
        </div>
        <span
          onClick={() => {
            sendSignupButtonPressedEvent(
              screenId.current,
              SCREEN_NAME,
              `${SCREEN_NAME}-redirect-to-home`
            );
            redirectToHome();
          }}
          className={styles.finishFlowButton}
          role="button"
          tabIndex={0}>
          <Label size="lg">
            {translate('components.brand-safety.sign-up.finished-flow.discover-more-campaigns')}
          </Label>
        </span>
      </div>
    </div>
  );
}

export default FinishedFlow;

import React, { useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Icon, Body } from '@lightricks/react-design-system';
import { v4 as uuid } from 'uuid';
import { INFO_BOX_STYLES, INFO_MESSAGES } from './constants/platformConstants';
import { useErrorHandling, usePlatformManagement, useFlowState } from './hooks/usePlatformSync';
import authenticateTiktok from '../../services/tiktok_creator_market_place/TiktokCreatorMarketPlace';
import authenticateYouTube from '../../services/youtube/Youtube';
import authenticateFacebook from '../../services/facebook/Facebook';
import { Platform, PLATFORM_TYPES } from './types/platforms';
import UnfinishedFlow from './UnfinishedFlow';
import FinishedFlow from './FinishedFlow';
import InstagramInstructions from './InstagramInstructions';
import useUserQuery from '../../hooks/queries/use-user-query';
import translate from '../../utils/translate';
import styles from './SyncPlatforms.module.scss';
import SIGNUP_SCREEN_PRESENTED_SCHEMA from '../../lib/delta/delta-schemas/ppWebCreatorsSignupScreenPresented';
import { flowNames } from '../../lib/delta/deltaConstants';
import DeltaHelper from '../../services/delta/DeltaHelper';
import Delta from '../../utils/wrappers/Delta';
import SIGNUP_SCREEN_DISMISSED_SCHEMA from '../../lib/delta/delta-schemas/ppWebCreatorsSignupScreenDismissed';
import SyncPlatformsContent from './SyncPlatformsContent';
import SIGNUP_STEP_STARTED_SCHEMA from '../../lib/delta/delta-schemas/ppWebCreatorsSignupStepStarted';
import SIGNUP_STEP_ENDED_SCHEMA from '../../lib/delta/delta-schemas/ppWebCreatorsSignupStepEnded';
import SIGNUP_FLOW_ENDED_SCHEMA from '../../lib/delta/delta-schemas/ppWebCreatorsSignupFlowEnded';

export type SyncPlatformsProps = {
  selectedPlatforms: Platform[];
  handleFlowTransition: () => void;
};

function signupStepInputValue(platform: string) {
  return `clicked connect to: ${platform}`;
}

const STORAGE_KEY = 'signup_steps_process_ids';

const getStoredProcessIds = (userId: string): Record<Platform, string> => {
  const stored = localStorage.getItem(STORAGE_KEY);
  const allUserIds = stored ? JSON.parse(stored) : {};

  const defaultProcessIds = PLATFORM_TYPES.reduce(
    (acc, platform) => ({
      ...acc,
      [platform]: ''
    }),
    {} as Record<Platform, string>
  );

  return allUserIds[userId] ? { ...defaultProcessIds, ...allUserIds[userId] } : defaultProcessIds;
};

const setStoredProcessId = (userId: string, platform: Platform, processId: string) => {
  const stored = localStorage.getItem(STORAGE_KEY);
  const allUserIds = stored ? JSON.parse(stored) : {};

  allUserIds[userId] = {
    ...getStoredProcessIds(userId),
    [platform]: processId
  };

  localStorage.setItem(STORAGE_KEY, JSON.stringify(allUserIds));
};

const clearStoredProcessId = (userId: string, platform: Platform) => {
  const stored = localStorage.getItem(STORAGE_KEY);
  const allUserIds = stored ? JSON.parse(stored) : {};

  if (allUserIds[userId]) {
    allUserIds[userId][platform] = '';

    if (Object.values(allUserIds[userId]).every((value) => value === '')) {
      delete allUserIds[userId];
    }

    localStorage.setItem(STORAGE_KEY, JSON.stringify(allUserIds));
  }
};

function SyncPlatforms(props: SyncPlatformsProps) {
  const { selectedPlatforms, handleFlowTransition } = props;
  const { user: userData } = useUserQuery({});
  const userIdentities = userData?.identities || [];
  const totalPlatforms =
    selectedPlatforms.filter((platform) => platform !== 'Facebook' && platform !== 'YouTube')
      .length + 1;
  const [searchParams] = useSearchParams();
  const brandName = searchParams.get('brand') || '';
  const onPlatformSync = searchParams.get('on_platform_sync') === 'true';

  const { error, setError, isClosingErrorBox, handleCloseErrorBox } = useErrorHandling();

  const {
    connectedPlatforms,
    filteredConnectedPlatforms,
    avatarUrl,
    loadingPlatform,
    setLoadingPlatform
  } = usePlatformManagement(selectedPlatforms, userIdentities);

  useEffect(() => {
    const storedProcessIds = getStoredProcessIds(userData.id);

    const finishedPlatforms = filteredConnectedPlatforms.filter(
      (platform) => storedProcessIds[platform]
    );
    finishedPlatforms.forEach((platform) => {
      const processId = storedProcessIds[platform];
      Delta.sendEvent(SIGNUP_STEP_ENDED_SCHEMA.name, {
        flow_id: flow.current.flow_id,
        process_id: processId,
        signup_provider: platform,
        request_type: 'connect platform',
        input_value: signupStepInputValue(platform),
        reason: 'success'
      });
      clearStoredProcessId(userData.id, platform);
    });
  }, [filteredConnectedPlatforms]);

  const {
    isUnfinishedFlow,
    setIsUnfinishedFlow,
    isFinishedFlow,
    showInstagramInstructions,
    setShowInstagramInstructions
  } = useFlowState(filteredConnectedPlatforms, totalPlatforms);

  const progressPercentage = useMemo(
    () => (filteredConnectedPlatforms.length / totalPlatforms) * 100,
    [filteredConnectedPlatforms.length, totalPlatforms]
  );

  const flowNotCompleted = useMemo(
    () => filteredConnectedPlatforms.length < totalPlatforms,
    [filteredConnectedPlatforms.length, totalPlatforms]
  );

  const { infoMessage, infoIcon } = useMemo(() => {
    const remainingPlatforms = selectedPlatforms.filter(
      (platform) => !filteredConnectedPlatforms.includes(platform)
    );
    const info =
      INFO_MESSAGES.find(({ count }) => count === filteredConnectedPlatforms.length) ||
      INFO_MESSAGES[0];

    let message = translate(info.message);
    if (info.count === 3 && remainingPlatforms) {
      message = translate(info.message, { social: remainingPlatforms });
    }

    return {
      infoMessage: message,
      infoIcon: info.icon
    };
  }, [filteredConnectedPlatforms, selectedPlatforms]);

  const flow = useRef(DeltaHelper.getOrCreateFlow(flowNames.brandsSafetyConnectPlatforms));
  const screenId = useRef(uuid());
  const sendDismissedEventRef = useRef<(flowId: string, screenId: string) => void>();
  useEffect(() => {
    sendDismissedEventRef.current = (flowId: string, inputScreenId: string) => {
      Delta.sendEvent(SIGNUP_SCREEN_DISMISSED_SCHEMA.name, {
        flow_id: flowId,
        screen_presentation_id: inputScreenId,
        signup_screen_name: 'Sync Platforms',
        presented_details: JSON.stringify({
          selectedPlatforms,
          filteredConnectedPlatforms,
          loadingPlatform
        })
      });
    };
  }, [selectedPlatforms, filteredConnectedPlatforms, loadingPlatform]);
  useEffect(() => {
    Delta.sendEvent(SIGNUP_SCREEN_PRESENTED_SCHEMA.name, {
      flow_id: flow.current.flow_id,
      screen_presentation_id: screenId.current,
      signup_screen_name: 'Sync Platforms',
      presented_details: JSON.stringify({
        selectedPlatforms,
        filteredConnectedPlatforms,
        loadingPlatform
      })
    });
    return () => {
      if (sendDismissedEventRef.current) {
        sendDismissedEventRef.current(flow.current.flow_id, screenId.current);
      }
    };
  }, []);

  const handleConnectPlatform = async (platform: Platform) => {
    const processId = uuid();
    setStoredProcessId(userData.id, platform, processId);
    const signupStepStartedPayload = {
      flow_id: flow.current.flow_id,
      process_id: processId,
      signup_provider: platform,
      request_type: 'connect platform',
      input_value: signupStepInputValue(platform),
      number_of_required_fields: 0,
      number_of_fields_completed: 0
    };
    Delta.sendEvent(SIGNUP_STEP_STARTED_SCHEMA.name, signupStepStartedPayload);
    await Delta.flushEvents();

    if (platform === 'Instagram') {
      setIsUnfinishedFlow(false);
      setShowInstagramInstructions(true);
      return;
    }
    try {
      switch (platform) {
        case 'TikTok':
          setStoredProcessId(userData.id, 'TikTok', processId);
          await authenticateTiktok(userData?.id, selectedPlatforms, brandName);
          break;
        case 'YouTube':
          setStoredProcessId(userData.id, 'YouTube', processId);
          await authenticateYouTube(userData?.id, selectedPlatforms, brandName);
          break;
        case 'Facebook':
          setStoredProcessId(userData.id, 'Facebook', processId);
          await authenticateFacebook(userData?.id, selectedPlatforms, brandName);
          break;
        default:
          throw new Error(`Platform ${platform} is not supported.`);
      }
    } catch (err) {
      clearStoredProcessId(userData.id, platform);
      setError(`Failed to connect to ${platform}. Please try again.`);
      Delta.sendEvent(SIGNUP_STEP_ENDED_SCHEMA.name, {
        ...signupStepStartedPayload,
        reason: 'failure',
        error: JSON.stringify(err)
      });
    } finally {
      setLoadingPlatform(null);
    }
  };

  const handleFinishConnecting = () => {
    const missingPlatforms = selectedPlatforms
      .filter((platform) => platform !== 'Facebook')
      .filter((platform) => !filteredConnectedPlatforms.includes(platform));

    if (missingPlatforms.length === 1) {
      handleConnectPlatform(missingPlatforms[0]);
      return;
    }
    setIsUnfinishedFlow(false);
  };

  const handleUnfinishedFlow = () => flowNotCompleted && setIsUnfinishedFlow(true);

  const renderErrorBox = () => (
    <div className={`${styles.errorBox} ${isClosingErrorBox ? styles.hide : ''}`}>
      <div
        className={styles.errorBoxCloseButton}
        onClick={handleCloseErrorBox}
        role="button"
        tabIndex={0}>
        <Icon name="Actions-Close-Small" size="large" appearance="secondary" />
      </div>
      <Icon name="Actions-Patch-Cancel-Line" size="medium" appearance="danger" />
      <Body size="md" className={styles.errorBoxMessage}>
        {error}
      </Body>
    </div>
  );

  const renderInfoBox = () => {
    const infoBoxStyle =
      INFO_BOX_STYLES[connectedPlatforms.length as keyof typeof INFO_BOX_STYLES] ||
      INFO_BOX_STYLES.default;

    return (
      <div className={`${styles.infoBox} ${infoBoxStyle}`}>
        <Icon name={infoIcon} size="large" appearance="primary" />
        <p>{infoMessage}</p>
      </div>
    );
  };

  if (isFinishedFlow) {
    Delta.sendEvent(SIGNUP_FLOW_ENDED_SCHEMA.name, {
      flow_id: flow.current.flow_id,
      flow_name: flow.current.flow_name,
      reason: 'success',
      ad_networks_connected: JSON.stringify(filteredConnectedPlatforms)
    });
    return <FinishedFlow />;
  }

  if (isUnfinishedFlow) {
    const missingPlatforms = selectedPlatforms
      .filter((platform) => platform !== 'Facebook')
      .filter((platform) => !filteredConnectedPlatforms.includes(platform));

    return (
      <UnfinishedFlow
        handleFinishConnecting={handleFinishConnecting}
        missingPlatform={missingPlatforms.length === 1 ? missingPlatforms[0] : undefined}
        handleBackToSelect={handleFlowTransition}
      />
    );
  }

  if (showInstagramInstructions) {
    return (
      <InstagramInstructions
        handleBack={() => setShowInstagramInstructions(false)}
        handleUnfinishedFlow={handleUnfinishedFlow}
        userId={userData?.id}
        selectedPlatforms={selectedPlatforms}
        handleContinueWithFacebook={handleFinishConnecting}
      />
    );
  }

  return (
    <SyncPlatformsContent
      handleFlowTransition={handleFlowTransition}
      handleUnfinishedFlow={handleUnfinishedFlow}
      flowNotCompleted={flowNotCompleted}
      progressPercentage={progressPercentage}
      avatarUrl={avatarUrl}
      error={error}
      renderErrorBox={renderErrorBox}
      renderInfoBox={renderInfoBox}
      PlatformListProps={{
        selectedPlatforms,
        connectedPlatforms,
        loadingPlatform,
        onConnectPlatform: handleConnectPlatform
      }}
      onPlatformSync={onPlatformSync}
    />
  );
}

export default SyncPlatforms;
